.footer {
  background-image: url('../images/footer.png');
  background-color:#042557; 
  background-size: cover;
  background-position-y: 92%;
  padding-top: 50px;
  height: 400px;
  position:inherit;


}
.footer-item {
  color: white !important;
  font-size:19px;
  font-weight:500;
}

#footer-head{
  color: white !important;
  font-weight:600;
}
@media (max-width: 768px){
  .copyright{
    background-color: #4490CB;
    height:40px;
   
    padding:20px;
  }

  .footer-logo{
    margin:0px 0px 0px 0px !important;
    padding: 0px !important;
    
  }
  .footer{
    height: auto !important;;
    margin-top:20px;
    background-image:url('../images/footer.png');
  }}
.footer-item {
  color: white !important;
}

ul {
  padding-left: 5px;
}

.email-div {
  color: white;
  font: Lato, 'sans-serif';
  font-weight: 400;
}

.email-input {
  height: 39px;
  border-radius: 5px;
  margin-bottom: 20px;
}

.buttonsubs {
  color: rgb(0, 183, 255);
  background-color: #ffffff;
  margin-top: -20px;
  margin-bottom: 10px;
  border: none;
  padding: 12px 10px 10px 10px;
  text-align: center !important;
  display: inline-block;
  font-size: 15px;
  font-weight: 900;
  border-radius: 4px;
  font-family: 'Lato', sans-serif;
  transition: all 0.2s ease-in;
}

.buttonsubs:hover {
  transform: scale(1.2);
}



.fa:hover{
	opacity:0.7;
}
/* Facebook */
.fa-facebook {
    background: #336de9;
    color: white;
    font-size: 20px;
    height:30px;
    width: 30px;
    padding:10px;
    margin-top:-5px;
}

.fa-instagram {
  background: #d41977;
  color: white;
  font-size: 20px;
  height:30px;
  width: 30px;
  padding:10px;
  margin-top:-5px;
}

.fa-youtube {
  background: #ee3232;
  color: white;
  font-size: 20px;
  height:30px;
  width: 30px;
  padding:10px;
  margin-top:-5px;
}

/* Twitter */
.fa-twitter {
    background: rgb(14, 109, 182);
    color: white;
    font-size: 20px;
    height:30px;
    width: 30px;
    padding:10px;
   }
   .fa-linkedin {
    background: rgb(1, 148, 158);
    color: white;
    font-size: 20px;
    height:30px;
    width: 30px;
    padding:10px;
    margin-top:10px;
}

.copytext{
  font-size:10px;
  color:#11428a;
}

.copyright{
  background-color: #4490CB;
  height:60px;
  padding:20px;
}
