@import url(https://fonts.googleapis.com/css2?family=Montserrat:ital,wght@0,100;0,200;0,300;0,500;1,100;1,200;1,300&display=swap);
body {
  margin: 0;
  font-family: -apple-system, BlinkMacSystemFont, 'Segoe UI', 'Roboto', 'Oxygen',
    'Ubuntu', 'Cantarell', 'Fira Sans', 'Droid Sans', 'Helvetica Neue',
    sans-serif;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
}

code {
  font-family: source-code-pro, Menlo, Monaco, Consolas, 'Courier New',
    monospace;
}

/*-----------------------------------------------businus.com_landing_page----------------*/

.box-container2:hover {
  background-color: #f7ebec;
  cursor: pointer;
}

.box-container3:hover {
  background-color: #ecfcea;
  cursor: pointer;
}
.box-container1:hover {
  background-color: rgb(214, 237, 240);
  cursor: pointer;
}

.sub-logo {
  width: 45% !important;
  position: absolute;
  left: 27% !important;
  top: 15% !important;
  -webkit-transition: all 0.4s ease-in-out;
  transition: all 0.4s ease-in-out;
}
.sub-logo-dashboard {
  width: 58% !important;
  position: absolute;
  left: 21% !important;
  top: 15% !important;
  -webkit-transition: all 0.4s ease-in-out;
  transition: all 0.4s ease-in-out;
}

.clipArt {
  height: 64vh;
  margin-top: 27%;
}
.desc-div {
  font-family: montserrat, "sans-serif";
  margin-top: 38% !important;
}
.desc {
  font-size: 20px;
  font-weight: 500;
  color: #113855;
  text-align: center;
}

.navbar {
  background-color: #4490cb;
  height: 61px;
  z-index: 9999;
  width: auto;
  box-shadow: 5px 4px 9px #c0c0c047;
  margin-bottom: 0 !important;
}

.favicon {
  height: 15px;
}

@media screen and (min-width: 768px) and (max-width: 1920px) {
  .box-container1 img {
    /* width: 50%; */
    position: absolute;
    /* left: 22%; */
    top: 5%;

    -webkit-transition: all 0.3s ease-in-out;

    transition: all 0.3s ease-in-out;
  }

  .box-container1 {
    background: #ffffff;
    height: calc(100vh - 90px);
  }
  .box-container3 {
    background: #ffffff;
    height: calc(100vh - 90px);
  }

  .box-container2 {
    background-color: #fbfbfc;
    height: calc(100vh - 90px);
  }

  .box-container2 img {
    width: 38vw;
    position: absolute;
    left: -2%;
    top: 5%;
    -webkit-transition: all 0.3s ease-in-out;
    transition: all 0.3s ease-in-out;
  }

  .box-container3 img {
    width: 38vw;
    position: absolute;
    left: -2%;
    top: 5%;

    -webkit-transition: all 0.3s ease-in-out;

    transition: all 0.3s ease-in-out;
  }
}

@media screen and (max-width: 768px) {
  .website {
    display: none !important;
  }

  .box-container1 {
    background: #ffffff;
    height: 55vh !important;
  }
  .box-container3 {
    background: #ffffff;
    height: 58vh !important;
  }

  .box-container2 {
    background-color: #fbfbfc;
    height: 58vh !important;
  }

  .email-input-get-in-touch {
    width: 80%;
    margin: 0px 28% 0px 11%;
  }

  .desc-div {
    font-family: montserrat, "sans-serif";
    margin-top: 34% !important;
  }
  .clipArt {
    height: 43vh;
    margin-top: 27%;
    width: 85vw;
}
  .box-container1 img {
    /* width: 50%; */
    width: 95vw;
    position: absolute;
    /* left: 22%; */
    top: 5%;

    -webkit-transition: all 0.3s ease-in-out;

    transition: all 0.3s ease-in-out;
  }

  .box-container2 img {
    width: 106vw;
    position: absolute;
    left: 2%;
    top: 7%;
    -webkit-transition: all 0.3s ease-in-out;
    transition: all 0.3s ease-in-out;
  }
  .box-container3 img {
    width: 99vw;
    position: absolute;
    left: 4%;
    top: 7%;
    -webkit-transition: all 0.3s ease-in-out;
    transition: all 0.3s ease-in-out;
}
.navbar-brand{
  margin-left:-20px !important;
}
  .form-control {
    display: block;
    width: 86% !important;
    height: calc(1.5em + 0.75rem + 2px);
    padding: 0.375rem 0.75rem;
    font-size: 17px;
    font-weight: 400;
    font-family: "Montserrat";
    line-height: 1.5;
    color: #495057;
    background-color: #fff;
    background-clip: padding-box;
    border: 1px solid #f1f1f1;
    border-radius: 0.25rem;
    -webkit-transition: border-color 0.15s ease-in-out, box-shadow 0.15s ease-in-out;
    transition: border-color 0.15s ease-in-out, box-shadow 0.15s ease-in-out;
  }
}

.rodal-dialog {
  position: absolute;
  top: 0;
  left: 0;
  color: white;
  right: 0;
  bottom: 0;
  margin: auto;
  z-index: 101;
  padding: 15px;
  background: #4490CB !important
        ;
  border-radius: 3px;
  box-shadow: 0 1px 3px rgb(0 0 0 / 20%);
}

.footer {
  background-image: url(/static/media/footer.dfef96b6.png);
  background-color:#042557; 
  background-size: cover;
  background-position-y: 92%;
  padding-top: 50px;
  height: 400px;
  position:inherit;


}
.footer-item {
  color: white !important;
  font-size:19px;
  font-weight:500;
}

#footer-head{
  color: white !important;
  font-weight:600;
}
@media (max-width: 768px){
  .copyright{
    background-color: #4490CB;
    height:40px;
   
    padding:20px;
  }

  .footer-logo{
    margin:0px 0px 0px 0px !important;
    padding: 0px !important;
    
  }
  .footer{
    height: auto !important;;
    margin-top:20px;
    background-image:url(/static/media/footer.dfef96b6.png);
  }}
.footer-item {
  color: white !important;
}

ul {
  padding-left: 5px;
}

.email-div {
  color: white;
  font: Lato, 'sans-serif';
  font-weight: 400;
}

.email-input {
  height: 39px;
  border-radius: 5px;
  margin-bottom: 20px;
}

.buttonsubs {
  color: rgb(0, 183, 255);
  background-color: #ffffff;
  margin-top: -20px;
  margin-bottom: 10px;
  border: none;
  padding: 12px 10px 10px 10px;
  text-align: center !important;
  display: inline-block;
  font-size: 15px;
  font-weight: 900;
  border-radius: 4px;
  font-family: 'Lato', sans-serif;
  -webkit-transition: all 0.2s ease-in;
  transition: all 0.2s ease-in;
}

.buttonsubs:hover {
  -webkit-transform: scale(1.2);
          transform: scale(1.2);
}



.fa:hover{
	opacity:0.7;
}
/* Facebook */
.fa-facebook {
    background: #336de9;
    color: white;
    font-size: 20px;
    height:30px;
    width: 30px;
    padding:10px;
    margin-top:-5px;
}

.fa-instagram {
  background: #d41977;
  color: white;
  font-size: 20px;
  height:30px;
  width: 30px;
  padding:10px;
  margin-top:-5px;
}

.fa-youtube {
  background: #ee3232;
  color: white;
  font-size: 20px;
  height:30px;
  width: 30px;
  padding:10px;
  margin-top:-5px;
}

/* Twitter */
.fa-twitter {
    background: rgb(14, 109, 182);
    color: white;
    font-size: 20px;
    height:30px;
    width: 30px;
    padding:10px;
   }
   .fa-linkedin {
    background: rgb(1, 148, 158);
    color: white;
    font-size: 20px;
    height:30px;
    width: 30px;
    padding:10px;
    margin-top:10px;
}

.copytext{
  font-size:10px;
  color:#11428a;
}

.copyright{
  background-color: #4490CB;
  height:60px;
  padding:20px;
}

